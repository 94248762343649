/* Run functions on document.ready */
$(document).ready(function(){
/* FancyBox set up */
    if ($('.fancybox, .fancybox-thumb, .fancybox-iframe, .gallery-page').length){
        var fbRegular = {openEffect: 'fade', closeEffect: 'fade', prevEffect: 'fade', nextEffect: 'fade', prevSpeed: 'slow', nextSpeed: 'slow', helpers: {title : {type : 'inside'}}};
        var fbGallery = {openEffect: 'fade', closeEffect: 'fade', prevEffect: 'fade', nextEffect: 'fade', prevSpeed: 'slow', nextSpeed: 'slow', mouseWheel: true, helpers: {title : {type : 'inside'}, thumbs: {width: 100, height: 100}}};
        var fbFlickr = {openEffect: 'fade', closeEffect: 'fade', prevEffect: 'fade', nextEffect: 'fade', prevSpeed: 'slow', nextSpeed: 'slow', mouseWheel: true, helpers: {title : {type : 'inside'}, thumbs: {width: 100, height: 100}}, afterLoad: function(){this.title = this.element.data('caption')}};
        var fbIFrame = {type: 'iframe', openEffect: 'none', closeEffect: 'none', prevEffect: 'fade', maxWidth: 770, maxHeight: 575, fitToView: false, width: '100%', height: '100%', autoSize: false, closeClick: false};

        $('a.fancybox').fancybox(fbRegular);
        $('a.fancybox-thumb').fancybox(fbGallery);
        $('a.fancybox-flickr').fancybox(fbFlickr);
        $('a.fancybox-iframe').fancybox(fbIFrame);

        /* Set up external video box */
        $('.ext-video').fancybox({openEffect: 'fade', closeEffect: 'fade', type: 'iframe', width: 480, height: 360, scrolling: 'no', autoDimensions: false, fitToView: false });

        /* Video player: Flowplayer */
        if ($('.video').length){
            $('a.video').click(function(e) {
                e.preventDefault();
                var container = $('<div class="video_box"></div>');
                container.flowplayer('//cdnjs.cloudflare.com/ajax/libs/flowplayer/5.4.6/flowplayer.swf', $(this).attr('href'));
                $.fancybox({ openEffect: 'fade', closeEffect: 'fade', content: container, width: 480, height: 360, scrolling: 'no', autoDimensions: false });
            });
        }

        if ($('.gallery-page').length > 0) {
            /* Flickr gallery, display from setID */
            /* Flickr account settings */
            var flickrKey = 'b740d14dd9ccc3dd1a9da2bc108b59e2';
            var flickrID = '127060938@N06';
            var imgPerPage = '50';
            var flickrAlbum = $('#albums');

            /* Flickr gallery */
            flickrAlbum.flickr({ user_id: flickrID, api_key: flickrKey, size: 'medium' });
            $('i.fa.fa-spinner').remove();
        }
    };
});

/* Run functions on window.load */
$(window).load(function(){

});
